/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import KarriereForm from '../../components/Forms/KrriereForm';
import ListSection from '../../components/ListSection';
import Link from '@fify.at/gatsby-theme-base/src/components/Link/Link';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';
import Text_calltoaction from '../../components/raw_templates/Text_calltoaction';

export default function Karriere({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Karriere"
      description="Machen Sie jetzt Karriere in der Immobilienbranche!"
      image={data.hero.childImageSharp.fluid}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Karriere"
        subtitle="Mit WirKaufenDeinHaus.at zum Erfolg"
        image={data.hero}
      />
      <Text_calltoaction
        container="small"
        sectionBackground="white"
        title="Machen Sie jetzt Karriere in der Immobilienbranche!"
        subtitle="Erfolgreich mit Immobilien."
        text="<p>Sie wollten schon immer in der Immobilienbranche Fuß fassen oder suchen als Immobilienmakler neue
        Möglichkeiten und Herausforderungen?</p>
        <p>Egal ob Sie bereits als Immobilienmakler tätig sind, sich gerade in
         Ausbildung befinden oder aber auch Quereinsteiger sind – gemeinsam mit unseren Partnerunternehmen finden wir für
         Sie die passende Möglichkeit, Ihre Mission in die Realität umzusetzen.</p>"
        buttons={[
          {
            text:
              'Aktuelle Jobangebote unserer Partnerunternehmen <i class="fal fa-arrow-right"></i>',
            href: 'https://donauimmo.at/de/unternehmen/karriere',
            target: '_blank',
          },
        ]}
      />
      {/*<div sx={{ marginBottom: 7 }}>*/}
      {/*  <Heading>Machen Sie jetzt Karriere in der Immobilienbranche!</Heading>*/}
      {/*  <Subheading>Setzen Sie Ihre Mission in Realität um</Subheading>*/}
      {/*</div>*/}
      {/*<p>*/}
      {/*  Sie wollten schon immer in der Immobilienbranchen Fuß fassen oder*/}
      {/*  suchen als Immobilienmakler neue Möglichkeiten und Herausforderungen?*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Egal ob Sie bereits als Immobilienmakler tätig sind, sich gerade in*/}
      {/*  Ausbildung befinden oder aber auch Quereinsteiger sind – wir*/}
      {/*  besprechen gerne mit Ihnen die Möglichkeiten bei uns oder unseren*/}
      {/*  Partnerunternehmen, Ihre Mission in die Realität umzusetzen.*/}
      {/*</p>*/}

      {/*<ListSection*/}
      {/*  background="muted"*/}
      {/*  title="Unser Angebot für Sie"*/}
      {/*  header="Unser Angebot im Überblick:"*/}
      {/*  text={[*/}
      {/*    {*/}
      {/*      listItem:*/}
      {/*        'Etablierte und führende Marken im Bereich Immobilien mit einem starken Branchen-Background',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      listItem: 'Krisensicherheit & Stabilität',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      listItem:*/}
      {/*        'Arbeiten mit erfolgreichen, standardisierten Abläufen in allen Bereichen',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      listItem: 'Zugriff auf ein großes Pool an Interessenten',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      listItem:*/}
      {/*        'Tätigkeit auf selbstständiger Basis oder auch im Angestelltenverhältnis möglich',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      listItem: 'Überdurchschnittlich hohe Provisionen',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      listItem:*/}
      {/*        'Professionelle Ausbildung in Theorie und Praxis in der hauseigenen Akademie mit Lehrbeauftragen anerkannter Universitäten',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      listItem:*/}
      {/*        'Intensive Einschulungsphase mit individuellem, persönlichem Coaching',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      listItem: 'Laufende Qualitätssicherung',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      listItem: 'Vernetzung und Teamstärke nutzen',*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}

      {/*<Section*/}
      {/*  sx={{*/}
      {/*    maxWidth: [null, null, 500, 760, 1100],*/}
      {/*    marginX: 'auto',*/}
      {/*    marginY: 7,*/}
      {/*    boxShadow: `0 0 30px rgba(0,0,0,0.1)`,*/}
      {/*    backgroundColor: '#fff',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Heading*/}
      {/*    sx={{ color: 'primary', fontWeight: 'bold', textAlign: 'center' }}*/}
      {/*  >*/}
      {/*    Starten Sie jetzt Ihre Karriere bei WirkaufendeinHaus.at!*/}
      {/*  </Heading>*/}
      {/*  <p>*/}
      {/*    Füllen Sie den nachfolgenden Fragebogen aus und senden Sie diesen*/}
      {/*    gemeinsam mit Ihren Unterlagen an uns. Nach Sichtung der eingehenden*/}
      {/*    Informationen, nehmen wir umgehend Kontakt mit Ihnen auf und*/}
      {/*    vereinbaren einen persönlichen Gesprächstermin.*/}
      {/*  </p>*/}
      {/*  <KarriereForm location={location} />*/}
      {/*  <p sx={{ '&&&': { marginTop: 4 } }}>*/}
      {/*    <small>*/}
      {/*      * <Link to="/datenschutzerklaerung/">Datenschutz</Link> ist uns*/}
      {/*      besonders wichtig! Seien Sie versichert, dass wir Ihre Daten stets*/}
      {/*      diskret und sicher verarbeiten.*/}
      {/*    </small>*/}
      {/*  </p>*/}
      {/*</Section>*/}
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-karriere" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
