/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  ControlledForm,
  handleNetlifySubmit,
  SubmitButton,
  TextField,
  TextArea,
  SelectField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Row, Col } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import React from 'react';
import * as Yup from 'yup';
import Alert from '@fify.at/gatsby-theme-base/src/components/Alert/Alert';
import DatePickerWithFormik from './DatePickerWithFormik';
import { Field, ErrorMessage } from 'formik';

export default function KarriereForm({ location, ...rest }) {
  const [isSubmitted, setSubmitted] = React.useState(false);
  if (isSubmitted) {
    return <Alert variant="success">Danke für Ihre Nachricht!</Alert>;
  }
  return (
    <ControlledForm
      name="karriere"
      initialValues={{
        vorname: '',
        nachname: '',
        telefon: '',
        email: '',
        bisherigeUnternehmung: '',
        bevorzugteRegion: '',
        warumAlsPartner: '',
        erfolgsfaktoren: '',
        nachricht: '',
      }}
      validationSchema={formSchema}
      onSubmit={(values, actions) =>
        // console.log(values)
        handleNetlifySubmit(values, actions, location.href).then(() =>
          setSubmitted(true)
        )
      }
    >
      <Row>
        <Col>
          <TextField label="Vorname" name="vorname" required />
        </Col>
        <Col>
          <TextField label="Nachname" name="nachname" required />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField label="Telefon" name="telefon" required />
        </Col>
        <Col>
          <TextField label="E-Mail" name="email" required required />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField label="Alter" name="alter" required />
        </Col>
      </Row>

      <TextArea
        label="Was ist Ihre bisherige Unternehmung?"
        name="bisherigeUnternehmung"
        rows="2"
        required
      />

      <TextArea
        label="Bevorzugte Region, in der Sie als Partner für uns tätig werden möchten:"
        name="bevorzugteRegion"
        rows="2"
        required
      />

      <TextArea
        label="Warum möchten Sie Partner von WirkaufendeinHaus.at werden?"
        name="warumAlsPartner"
        rows="2"
        required
      />

      <TextArea
        label="Ihre 3 Erfolgsfaktoren eine partnerschaftliche Zusammenarbeit mit WirkaufendeinHaus.at:"
        name="erfolgsfaktoren"
        rows="2"
        required
      />

      <TextArea
        label="Weitere Informationen meinerseits:"
        name="nachricht"
        rows="5"
      />

      <SubmitButton>
        Jetzt bewerben <i class="fal fa-arrow-right"></i>
      </SubmitButton>
    </ControlledForm>
  );
}

const formSchema = Yup.object().shape({
  vorname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  nachname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  email: Yup.string()
    .trim()
    .email('Bitte eine gültige E-Mail-Adresse eingeben')
    .required('Dieses Feld ist verpflichtend'),
});
